import { useImageResolver } from "api/hooks/useImageResolver";
import type {
  CommunityFeedAudienceDto,
  CommunityFeedAudienceGroupDto,
  CommunityFeedAudienceProjectConnectionDto,
  CommunityFeedAudienceProjectDto,
  CommunityFeedSelectableAudienceDto,
  CopilotResponseDto,
  MessageCreatedEntityDto,
  MessageCreateRequest,
} from "api/types";
import iconAnnotationAlert from "assets/icons/annotation-alert.svg";
import projectIcon from "assets/icons/building-04.svg";
import connectionIcon from "assets/icons/building-07.svg";
import iconCheck from "assets/icons/check.svg";
import iconInfoCircle from "assets/icons/info-circle.svg";
import iconReverseLeft from "assets/icons/reverse-left.svg";
import iconReverseRight from "assets/icons/reverse-right.svg";
import MailManImage from "assets/images/mail-man.svg?react";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { CreatePostAppPreview } from "components/CreatePostAppPreview/CreatePostAppPreview";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormDocumentInput } from "components/Form/FormDocumentInput";
import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormScheduleInput } from "components/Form/FormScheduleInput";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { formatDistance } from "components/FormattedDistance/FormattedDistance";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { TipsModal } from "components/MessageTipsModal/MessageTipsModal";
import { Modal } from "components/Modal/Modal";
import { Notice } from "components/Notice/Notice";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Select } from "components/Select/Select";
import { Switch } from "components/Switch/Switch";
import { Capture1, Capture2, Headline3, Headline4, Subtitle2 } from "components/Text/Text";
import { addHours, addMinutes } from "date-fns";
import { validateSize } from "helpers/file-size";
import { createRequiredStringRule } from "helpers/rules";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { GroupIcon } from "modules/community-groups/components/GroupIcons";
import { MAX_AMOUNT_IMAGES_PER_POST } from "modules/messages/constants";
import { useConfig } from "providers/ConfigProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import type { OptimizeType } from "./Loader";

export interface LayoutProps {
  postAudience: CommunityFeedAudienceDto;
  optimizePost: ({
    input,
    option,
  }: {
    input: string;
    option?: Exclude<OptimizeType, "refresh">;
  }) => Promise<CopilotResponseDto>;
  isOptimizingPost: boolean;
  optimizingPostSetting: Exclude<OptimizeType, "refresh"> | undefined;
  onSubmit: (data: {
    payload: MessageCreateRequest;
    images: FormImage[];
    documents: FormDocument[];
  }) => Promise<MessageCreatedEntityDto>;
  isSubmitting: boolean;
  defaultFormValues: Partial<FormValues>;
}

export interface FormValues {
  type: Exclude<MessageCreateRequest["type"], "announcementReadOnly">;
  title: string;
  body: string;
  audience?:
    | { type: "project"; project: CommunityFeedAudienceProjectDto }
    | { type: "projectConnection"; projectConnection: CommunityFeedAudienceProjectConnectionDto };
  group?: CommunityFeedAudienceGroupDto;
  groupAudience?: CommunityFeedSelectableAudienceDto;
  images: FormImage[];
  documents: FormDocument[];
  scheduled: DatePickerValue;
}

export function Layout({
  postAudience,
  optimizePost,
  isOptimizingPost,
  optimizingPostSetting,
  onSubmit,
  isSubmitting,
  defaultFormValues,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMultipleImagesAllowedForCommunityPost = useConfig("isMultipleImagesAllowedForCommunityPost");

  const [isTipsModalOpen, tipsModalHandler] = useBool(false);
  const [isCancelModalOpen, cancelModalHandler] = useBool(false);
  const [isCommentAllowed, commentAllowedHandler] = useBool(true);
  const [isSuccessModalOpen, successModalHandler] = useBool(false);

  const [originalText, setOriginalText] = useState("");
  const [copilotText, setCopilotText] = useState("");
  const [isCopilotText, setIsCopilotText] = useState(false);
  const [audienceReached, setAudienceReached] = useState<(MessageCreatedEntityDto & { groupId?: string }) | null>(null);

  const form = useForm<FormValues>({ defaultValues: defaultFormValues });

  useEffect(() => {
    form.register("audience", {
      validate: (_, formValues) =>
        !formValues.audience && !formValues.group
          ? t("components.form.error.required", {
              inputName: t("page.admin-create-post.form.audience.short-label"),
            })
          : undefined,
    });
  }, [form, t]);

  const audience = useWatch({ control: form.control, name: "audience" });
  const group = useWatch({ control: form.control, name: "group" });
  const groupAudience = useWatch({ control: form.control, name: "groupAudience" });
  const messageType = useWatch({ control: form.control, name: "type" });
  const messageTitle = useWatch({ control: form.control, name: "title" });
  const messageBody = useWatch({ control: form.control, name: "body" });
  const images = useWatch({ control: form.control, name: "images" });
  const documents = useWatch({ control: form.control, name: "documents" });

  const minScheduled = useMemo(() => addMinutes(new Date(), 5), []);

  const improve = useCallback(
    async function improve(way: OptimizeType) {
      if (!isCopilotText) {
        setOriginalText(messageBody);
      }

      const option = way === "refresh" ? undefined : way;
      const result = await optimizePost({
        input: messageBody,
        option: option,
      });
      setCopilotText(result.generatedText);
      setIsCopilotText(true);
      form.setValue("body", result.generatedText);
    },
    [isCopilotText, optimizePost, messageBody, form],
  );

  function handleFormReset() {
    form.reset();
    setIsCopilotText(false);
    setOriginalText("");
    setCopilotText("");
    setAudienceReached(null);
    successModalHandler.setFalse();
  }

  function setProjectAsAudience(audience: CommunityFeedAudienceProjectDto) {
    form.setValue("audience", { type: "project", project: audience }, { shouldValidate: true });
    form.setValue("group", undefined);
    form.setValue("groupAudience", undefined);
  }

  function setProjectConnectionAsAudience(audience: CommunityFeedAudienceProjectConnectionDto) {
    form.setValue("audience", { type: "projectConnection", projectConnection: audience }, { shouldValidate: true });
    form.setValue("group", undefined);
    form.setValue("groupAudience", undefined);
  }

  function setGroupAsAudience(group: CommunityFeedAudienceGroupDto) {
    form.setValue("group", group);
    form.setValue("audience", undefined, { shouldValidate: true });

    if (group.defaultPostingLevel === "project") {
      form.setValue(
        "groupAudience",
        group.selectableAudience.find((x) => x.type === "project"),
      );
    } else {
      form.setValue(
        "groupAudience",
        group.selectableAudience.find((x) => x.type === "projectConnection"),
      );
    }
  }

  function setGroupAudience(groupAudience: CommunityFeedSelectableAudienceDto) {
    form.setValue("groupAudience", groupAudience);
  }

  function resetBodyToOriginal() {
    setIsCopilotText(false);
    form.setValue("body", originalText);
  }

  function resetBodyToCopilot() {
    setIsCopilotText(true);
    form.setValue("body", copilotText);
  }

  async function handleCreate() {
    const formValues = form.getValues();

    const payload: MessageCreateRequest = {
      projectConnectionId:
        formValues.audience?.type === "projectConnection"
          ? formValues.audience.projectConnection.id
          : groupAudience?.type === "projectConnection"
            ? groupAudience.projectConnection?.id
            : undefined,
      groupId: formValues.group ? formValues.group?.id : undefined,
      type: formValues.type === "announcement" && !isCommentAllowed ? "announcementReadOnly" : formValues.type,
      title: formValues.title,
      content: formValues.body,
      scheduledFor: formValues.scheduled ? formValues.scheduled.toISOString() : undefined,
      isHiddenFromAdmins: false,
    };

    const res = await onSubmit({
      payload,
      images: formValues.images,
      documents: formValues.documents,
    });

    setAudienceReached({ ...res, groupId: formValues.group?.id });
    successModalHandler.setTrue();
  }

  const messageTypes = useMemo(() => {
    if (group && groupAudience) {
      const details = groupAudience.type === "project" ? groupAudience.project : groupAudience.projectConnection;

      return (
        [
          details?.canCreatePost ? "undefined" : undefined,
          details?.canCreateAnnouncement ? "announcement" : undefined,
        ] as const
      ).filter(isDefined);
    }

    if (audience) {
      const details = audience.type === "project" ? audience.project : audience.projectConnection;

      return (
        [
          details.canCreatePost ? "undefined" : undefined,
          details.canCreateAnnouncement ? "announcement" : undefined,
        ] as const
      ).filter(isDefined);
    }

    return [];
  }, [audience, group, groupAudience]);

  useEffect(() => {
    if (!messageTypes.includes(messageType)) {
      form.setValue("type", messageTypes[0]);
      form.clearErrors("type");
    }
  }, [form, messageTypes, messageType]);

  const noAudienceSelected = !audience && !group;
  const wasPostedToGroup = defaultFormValues.group != null && audienceReached?.groupId === defaultFormValues.group?.id;

  return (
    <DocumentPaper
      theme="wide"
      title={t("page.admin-create-post.title")}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.message-feed.title"),
              to: routes.messageFeed.list({ slug }),
            },
            {
              name: t("page.admin-create-post.breadcrumbs.self"),
            },
          ]}
        />
      }
    >
      <Form formMethods={form} onSubmit={handleCreate}>
        <div className="grid size-full grid-cols-1 gap-2 @6xl:grid-cols-5">
          <div className="flex flex-col gap-4 pr-4">
            <Headline4>{t("page.admin-create-post.section.details")}</Headline4>
            <FormContent maxWidth="xl">
              <FormField label={t("page.admin-create-post.form.audience.label")} required>
                <FormErrorWrapper name="audience" encircle>
                  <div className="flex flex-col gap-2">
                    {postAudience.project ? (
                      <button
                        className={twJoin(
                          "flex items-center gap-2 rounded-md border px-4 py-1.5 text-left hover:border-grey-darker",
                          audience?.type === "project"
                            ? "!border-aop-basic-blue"
                            : "border-grey-lighter font-semibold text-grey-light",
                        )}
                        data-testid="project-audience-btn"
                        onClick={() => setProjectAsAudience(postAudience.project!)}
                        type="button"
                      >
                        <span
                          className={twJoin(
                            "flex h-6 shrink-0 items-center",
                            noAudienceSelected ? "text-black" : "text-grey-lighter",
                          )}
                        >
                          {audience?.type === "project" ? (
                            <Icon name={iconCheck} size={16} />
                          ) : (
                            <Icon name={projectIcon} size={16} />
                          )}
                        </span>
                        <Capture2
                          className={
                            audience?.type === "project"
                              ? "font-semibold"
                              : noAudienceSelected
                                ? "text-black"
                                : undefined
                          }
                        >
                          {t("page.admin-create-post.form.audience.all-residents", {
                            name: postAudience.project.name,
                          })}
                        </Capture2>
                      </button>
                    ) : null}
                    {postAudience.projectConnections.length > 0
                      ? postAudience.projectConnections.map((connection) => (
                          <button
                            className={twJoin(
                              "flex items-center gap-2 rounded-md border px-4 py-1.5 text-left hover:border-grey-darker",
                              audience?.type === "projectConnection" && audience.projectConnection.id === connection.id
                                ? "!border-aop-basic-blue"
                                : "border-grey-lighter text-grey-light",
                            )}
                            data-testid="connection-audience-btn"
                            key={connection.id}
                            onClick={() => setProjectConnectionAsAudience(connection)}
                            type="button"
                          >
                            <span
                              className={twJoin(
                                "flex h-6 shrink-0 items-center",
                                noAudienceSelected ? "text-black" : "text-grey-lighter",
                              )}
                            >
                              {audience?.type === "projectConnection" &&
                              audience.projectConnection.id === connection.id ? (
                                <Icon name={iconCheck} size={16} />
                              ) : (
                                <Icon name={connectionIcon} size={16} />
                              )}
                            </span>
                            <Capture2
                              className={
                                audience?.type === "projectConnection" &&
                                audience.projectConnection.id === connection.id
                                  ? "font-semibold"
                                  : noAudienceSelected
                                    ? "text-black"
                                    : undefined
                              }
                            >
                              {t("page.admin-create-post.form.audience.all-residents", {
                                name: connection.name,
                              })}
                            </Capture2>
                          </button>
                        ))
                      : null}
                    {(postAudience.project || postAudience.projectConnections.length > 0) &&
                    (postAudience.interestGroups.length > 0 ||
                      postAudience.helpCategories.length > 0 ||
                      postAudience.realEstateGroups.length > 0) ? (
                      <Capture2>{t("page.admin-create-post.form.audience.group.label")}</Capture2>
                    ) : null}
                    <Select<CommunityFeedAudienceGroupDto>
                      placeholder={t("page.admin-create-post.form.audience.group.placeholder")}
                      selected={group}
                      items={[
                        ...postAudience.realEstateGroups,
                        ...postAudience.interestGroups,
                        ...postAudience.helpCategories,
                      ]}
                      onChange={setGroupAsAudience}
                      keySelector={(x) => x.id}
                      renderSelected={(x) => <GroupOption group={x} isPreview />}
                      renderOption={(x) => <GroupOption group={x} />}
                      groupSelector={(x) => {
                        switch (x.type) {
                          case "interest":
                            return t("page.admin-create-post.form.audience.group.type.group");
                          case "helpCategory":
                            return t("page.admin-create-post.form.audience.group.type.help");
                          case "realEstate":
                            return t("page.admin-create-post.form.audience.group.type.real-estate");
                          default:
                            return "";
                        }
                      }}
                    />
                    {group &&
                      (group.selectableAudience.length > 1 ||
                        (group.selectableAudience.length === 1 &&
                          group.selectableAudience[0].type === "projectConnection")) && (
                        <>
                          <Capture2>{t("page.admin-create-post.form.audience.audience.label")}</Capture2>

                          <Select
                            placeholder={t("page.admin-create-post.form.audience.group.placeholder")}
                            selected={groupAudience}
                            items={group.selectableAudience}
                            onChange={setGroupAudience}
                            keySelector={(x) => x.type + "-" + (x.project?.id || x.projectConnection?.id)}
                            renderOption={(x) => (
                              <div className="flex w-full items-center justify-between">
                                <span>{x.project?.name || x.projectConnection?.name}</span>
                                <span className="ml-1 text-sm italic text-grey-dark">
                                  {t("page.admin-create-post.form.audience.group.members", {
                                    count: x.project?.audienceCount || x.projectConnection?.audienceCount,
                                  })}
                                </span>
                              </div>
                            )}
                            groupSelector={(x) => {
                              switch (x.type) {
                                case "project":
                                  return t("page.admin-create-post.form.audience.group.type.project");
                                default:
                                  return t("page.admin-create-post.form.audience.group.type.connection");
                              }
                            }}
                            disabled={group.selectableAudience.length === 1}
                          />
                        </>
                      )}
                  </div>
                </FormErrorWrapper>
              </FormField>
              <FormField label={t("page.admin-create-post.form.type")} required>
                <FormSelect<FormValues, FormValues["type"]>
                  name="type"
                  items={messageTypes}
                  keySelector={(x) => x}
                  renderOption={(x) => {
                    switch (x) {
                      case "undefined":
                        return t("page.admin-create-post.form.type.default");
                      case "announcement":
                        return t("page.admin-create-post.form.type.announcement");
                    }
                  }}
                  disabled={messageTypes.length === 0}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.admin-create-post.form.type"),
                    }),
                  }}
                />
              </FormField>
              {messageType === "announcement" && (
                <Notice
                  icon={iconAnnotationAlert}
                  message={t("page.admin-create-post.form.type.announcement.tooltip")}
                  data-testid="announcement-email-alert"
                />
              )}
              <FormField label={t("page.admin-create-post.form.scheduling.title")}>
                <FormScheduleInput
                  name="scheduled"
                  canEdit
                  min={minScheduled}
                  defaultValue={addHours(new Date(), 1)}
                  rules={{
                    validate: {
                      laterThanMin: (date) => {
                        if (!date) {
                          return undefined;
                        }

                        return date < minScheduled
                          ? t("page.message-feed.create.schedule.datetime.error.must-be-in-future")
                          : undefined;
                      },
                    },
                  }}
                />
              </FormField>
            </FormContent>
          </div>
          <div className="flex flex-col gap-4 @6xl:col-span-3">
            <Headline4 className="@6xl:pl-4">{t("page.admin-create-post.section.content")}</Headline4>
            <FormContent
              className="border-t border-grey-lightest @6xl:border-l @6xl:border-t-0 @6xl:px-4"
              maxWidth="4xl"
            >
              <FormField label={t("page.admin-create-post.form.title")} required>
                <FormInput<FormValues>
                  name="title"
                  placeholder={t("page.admin-create-post.form.title.placeholder")}
                  rules={{
                    validate: {
                      required: createRequiredStringRule(t, "page.admin-create-post.form.title"),
                    },
                  }}
                />
              </FormField>
              <FormField label={t("page.admin-create-post.form.message")} required>
                <FormTextArea<FormValues>
                  name="body"
                  placeholder={t("page.admin-create-post.form.message.placeholder")}
                  rules={{
                    validate: {
                      required: createRequiredStringRule(t, "page.admin-create-post.form.message"),
                    },
                  }}
                  large
                  scrollbar
                  toolbar={
                    <div className="flex h-fit w-full items-center gap-2 overflow-x-auto rounded-t-lg bg-grey-lightest p-2 ring-1 ring-grey-lighter">
                      <Button
                        styling="tertiary"
                        data-testid="copilot-generate-message-btn"
                        disabled={isOptimizingPost || messageBody.trim().split(" ").length < 3}
                        onClick={() => improve("refresh")}
                        isLoading={isOptimizingPost && optimizingPostSetting === undefined}
                      >
                        <Capture1>{t("page.admin-create-post.form.message.copilot.generate")}</Capture1>
                      </Button>
                      <Button
                        styling="tertiary"
                        disabled={isOptimizingPost || !isCopilotText}
                        onClick={() => improve("makeShorter")}
                        isLoading={isOptimizingPost && optimizingPostSetting === "makeShorter"}
                      >
                        <Capture1>{t("page.admin-create-post.form.message.copilot.shorten")}</Capture1>
                      </Button>
                      <Button
                        styling="tertiary"
                        disabled={isOptimizingPost || !isCopilotText}
                        onClick={() => improve("makeMorePositive")}
                        isLoading={isOptimizingPost && optimizingPostSetting === "makeMorePositive"}
                      >
                        <Capture1>{t("page.admin-create-post.form.message.copilot.positive")}</Capture1>
                      </Button>
                      <Button
                        styling="tertiary"
                        disabled={isOptimizingPost || !isCopilotText}
                        onClick={() => improve("makeUnderstandable")}
                        isLoading={isOptimizingPost && optimizingPostSetting === "makeUnderstandable"}
                      >
                        <Capture1>{t("page.admin-create-post.form.message.copilot.simplify")}</Capture1>
                      </Button>
                      <div className="h-8 w-px bg-grey-lighter" />
                      <Button
                        styling="tertiary"
                        data-testid="revert-copilot-message"
                        disabled={!isCopilotText || isOptimizingPost}
                        onClick={resetBodyToOriginal}
                        className={!isCopilotText || isOptimizingPost ? "fill-grey-dark" : undefined}
                      >
                        <Icon name={iconReverseLeft} size={16} />
                      </Button>
                      <Button
                        styling="tertiary"
                        disabled={isCopilotText || copilotText.length === 0 || isOptimizingPost}
                        onClick={resetBodyToCopilot}
                        className={
                          isCopilotText || copilotText.length === 0 || isOptimizingPost ? "fill-grey-dark" : undefined
                        }
                      >
                        <Icon name={iconReverseRight} size={16} />
                      </Button>
                      <button
                        className="ml-auto flex items-center gap-3 whitespace-nowrap rounded-md border border-green-darker bg-green-lightest p-2 text-green-darker hover:bg-green-lighter"
                        type="button"
                        onClick={tipsModalHandler.setTrue}
                      >
                        <Icon name={iconInfoCircle} size={16} />
                        <Capture2>{t("page.admin-create-post.form.message.tips")}</Capture2>
                      </button>
                    </div>
                  }
                />
              </FormField>
              {messageType === "announcement" && (
                <FormField label={t("page.admin-create-post.form.commenting")}>
                  <div className="flex items-center gap-2">
                    <Switch
                      data-testid="comment-toggle"
                      isChecked={isCommentAllowed}
                      onChange={commentAllowedHandler.toggle}
                    />
                    <Subtitle2 className="font-normal">
                      {isCommentAllowed
                        ? t("page.admin-create-post.form.commenting.enabled")
                        : t("page.admin-create-post.form.commenting.disabled")}
                    </Subtitle2>
                  </div>
                </FormField>
              )}
              <FormField label={t("page.admin-create-post.form.file")}>
                <div className="flex gap-2">
                  {documents.length === 0 && (
                    <FormImageInput<FormValues, "images">
                      name="images"
                      texts={{
                        add: t("page.admin-create-post.form.file.image"),
                      }}
                      nOfImages={isMultipleImagesAllowedForCommunityPost ? MAX_AMOUNT_IMAGES_PER_POST : 1}
                      rules={{
                        validate: {
                          size(image) {
                            if (image) {
                              return validateSize(t, image);
                            }
                          },
                        },
                      }}
                    />
                  )}
                  {images.length === 0 && (
                    <FormDocumentInput<FormValues, "documents">
                      name="documents"
                      accept="application/pdf"
                      withPreview
                      texts={{
                        add: t("page.admin-create-post.form.file.doc"),
                      }}
                      rules={{
                        validate: {
                          size(doc) {
                            if (doc) {
                              return validateSize(t, doc);
                            }
                          },
                        },
                      }}
                    />
                  )}
                </div>
              </FormField>
            </FormContent>
          </div>
          <CreatePostAppPreview
            sectionTitle={t("page.admin-create-post.section.preview")}
            type={messageType}
            title={messageTitle}
            body={messageBody}
            audience={audience}
            group={group?.name}
            images={images}
            documents={documents}
          />
          <div className="flex gap-4 justify-self-end @6xl:col-span-5">
            <Button styling="secondary" onClick={cancelModalHandler.setTrue} disabled={isSubmitting}>
              {t("common.action.cancel")}
            </Button>
            <Button styling="primary" type="submit" isLoading={isSubmitting}>
              {t("page.admin-create-post.post-button")}
            </Button>
          </div>
        </div>
      </Form>
      <TipsModal isOpen={isTipsModalOpen} onClose={tipsModalHandler.setFalse} />
      <ConfirmModal
        id="cancel-modal"
        data-testid="cancel-modal"
        title={t("page.admin-create-post.cancel-modal.title")}
        description={t("page.admin-create-post.cancel-modal.description")}
        isOpen={isCancelModalOpen}
        shouldCloseOnEsc
        onReject={cancelModalHandler.setFalse}
        rejectBtnProps={{
          text: t("common.action.cancel"),
        }}
        onResolve={() => navigate(routes.messageFeed.list({ slug }))}
        resolveBtnProps={{
          text: t("common.action.confirm"),
        }}
        isLoading={false}
      />
      <Modal isOpen={isSuccessModalOpen} onRequestClose={handleFormReset}>
        <div className="flex flex-col items-center gap-4 px-16 py-8">
          <Headline3>{t("page.admin-create-post.success-modal.title")}</Headline3>
          <MailManImage />
          {audienceReached?.addressCount !== undefined ||
          audienceReached?.unknownAddressCount !== undefined ||
          audienceReached?.audienceResidentsCount !== undefined ? (
            <div className="flex flex-col items-center gap-1">
              <Subtitle2 className="font-normal">
                {t("page.admin-create-post.success-modal.audience-reached.description.delivered-to")}
              </Subtitle2>
              <div>
                {audienceReached.addressCount !== undefined && audienceReached.addressCount > 0 && (
                  <Subtitle2 className="font-normal">
                    <Trans
                      i18nKey="page.admin-create.post.success-modal.audience-reached.description.addresses"
                      values={{
                        addressCount: audienceReached.addressCount,
                      }}
                      count={audienceReached.addressCount}
                      components={{
                        b: <b />,
                      }}
                    />
                  </Subtitle2>
                )}{" "}
                {audienceReached.unknownAddressCount !== undefined && audienceReached.unknownAddressCount > 0 && (
                  <Subtitle2 className="font-normal">
                    <Trans
                      i18nKey="page.admin-create.post.success-modal.audience-reached.description.unknown-addresses"
                      values={{
                        unknownAddressCount: audienceReached.unknownAddressCount,
                      }}
                      count={audienceReached.unknownAddressCount}
                      components={{
                        b: <b />,
                      }}
                    />
                  </Subtitle2>
                )}
              </div>
              {audienceReached.audienceResidentsCount !== undefined ? (
                audienceReached.audienceResidentsCount === 0 ? (
                  <Subtitle2 className="font-normal">
                    <Trans
                      i18nKey="page.admin-create-post.success-modal.audience-reached.description.residents.none"
                      values={{
                        residentCount: audienceReached.audienceResidentsCount,
                      }}
                      components={{
                        b: <b />,
                      }}
                    />
                  </Subtitle2>
                ) : (
                  <Subtitle2 className="font-normal">
                    <Trans
                      i18nKey="page.admin-create-post.success-modal.audience-reached.description.residents"
                      values={{
                        residentCount: audienceReached.audienceResidentsCount,
                      }}
                      count={audienceReached.audienceResidentsCount}
                      components={{
                        b: <b />,
                      }}
                    />
                  </Subtitle2>
                )
              ) : null}
            </div>
          ) : (
            <Subtitle2 className="font-normal">{t("page.admin-create-post.success-modal.description")}</Subtitle2>
          )}
          <div className="flex flex-col items-center gap-4 pt-6">
            <Button styling="tertiary" onClick={handleFormReset}>
              {t("page.admin-create-post.success-modal.button.another-message")}
            </Button>
            <Button
              styling="tertiary"
              onClick={() =>
                wasPostedToGroup
                  ? navigate(
                      defaultFormValues.group?.type === "helpCategory"
                        ? routes.helpCategories.details({ slug, id: defaultFormValues.group.id })
                        : routes.interestGroups.details({ slug, id: defaultFormValues.group!.id }),
                    )
                  : navigate(routes.messageFeed.list({ slug }))
              }
            >
              {wasPostedToGroup
                ? t("page.admin-create-post.success-modal.button.group-done")
                : t("page.admin-create-post.success-modal.button.done")}
            </Button>
          </div>
        </div>
      </Modal>
    </DocumentPaper>
  );
}

function GroupOption({ group, isPreview }: { group: CommunityFeedAudienceGroupDto; isPreview?: boolean }) {
  const resolveImage = useImageResolver();
  const { i18n, t } = useTranslation();

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-2">
        {group.isResidentGroup && group.image ? (
          <img src={resolveImage(group.image, "sm")} alt={group.name} className="size-6 rounded-full" />
        ) : (
          <span className="size-6 p-1">
            <GroupIcon icon={group.icon} size={16} />
          </span>
        )}
        <div className="flex flex-col">
          <span>{group.name}</span>
          {!isPreview && (
            <span className="text-xs text-grey">
              {group.lastActivityAt
                ? t("page.admin-create-post.form.audience.group.last-activity", {
                    time: formatDistance(i18n, { start: new Date(group.lastActivityAt) }),
                  })
                : t("page.admin-create-post.form.audience.group.no-activity")}
            </span>
          )}
        </div>
      </div>
      {group.selectableAudience.length === 1 ? (
        <span className="ml-1 text-sm italic text-grey-dark">
          {t("page.admin-create-post.form.audience.group.members", {
            count:
              group.selectableAudience[0].type === "project"
                ? group.selectableAudience[0].project!.audienceCount
                : group.selectableAudience[0].projectConnection!.audienceCount,
          })}
        </span>
      ) : null}
    </div>
  );
}
