import { Tooltip } from "components/Tooltip/Tooltip";
import { twResolve } from "helpers/tw-resolve";
import type React from "react";
import type { LinkProps } from "react-router-dom";
import { NavLink } from "react-router-dom";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { ButtonLoader } from "./ButtonLoader";

export type ButtonBaseProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type"> & {
  children: React.ReactNode;
  title?: string;
  "data-testid"?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  isPressed?: boolean;
  isLoading?: boolean;
};

export type ButtonProps = ButtonBaseProps & {
  styling?: ButtonVariants["styling"];
  size?: ButtonVariants["size"];
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
} & (
    | {
        type: "link";
        href: string;
        state?: LinkProps["state"];
        target?: LinkProps["target"];
        isExternal?: boolean;
      }
    | {
        type?: "button" | "submit";
        href?: never;
        state?: never;
        target?: never;
        isExternal?: never;
      }
  ) &
  (
    | {
        icon: React.ReactNode;
        iconPosition?: "left" | "right";
      }
    | {
        icon?: never;
        iconPosition?: never;
      }
  );

export function Button({
  children,
  title,
  styling = "primary",
  type,
  "data-testid": dataTestId,
  className,
  href,
  target,
  state,
  icon,
  iconPosition = "left",
  size = "md",
  disabled,
  isPressed,
  isLoading,
  isExternal,
  onClick,
  ...otherProps
}: ButtonProps): React.ReactNode {
  const propsCommon = {
    onClick,
    "data-testid": dataTestId,
    "aria-pressed": isPressed,
    className: twResolve(
      buttonVariants({
        styling,
        size,
      }),
      className,
    ),
  };

  const Content = (
    <>
      {iconPosition === "left" && icon}
      {children}
      {iconPosition === "right" && icon}
    </>
  );

  if (type === "link") {
    if (isExternal) {
      return (
        <Tooltip tooltip={title}>
          <a rel="noopener noreferrer" {...{ ...propsCommon, target, href }}>
            {Content}
          </a>
        </Tooltip>
      );
    }

    return (
      <Tooltip tooltip={title}>
        <NavLink to={href} {...{ ...propsCommon, state, target }}>
          {Content}
        </NavLink>
      </Tooltip>
    );
  }

  return (
    <Tooltip tooltip={title}>
      <button type={type || "button"} disabled={disabled || isLoading} {...propsCommon} {...otherProps}>
        {Content}
        <ButtonLoader isVisible={isLoading} />
      </button>
    </Tooltip>
  );
}

export const buttonVariants = tv({
  base: [
    "group relative flex w-fit shrink-0 cursor-pointer items-center justify-center gap-2 truncate rounded-lg text-center text-base focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-1",
    "tracking-normal no-underline transition-colors duration-200 disabled:cursor-not-allowed",
  ],
  variants: {
    styling: {
      primary:
        "bg-aop-basic-blue font-semibold text-white hover:bg-aop-basic-blue-dark focus-visible:ring-blue-dark disabled:bg-grey-lighter disabled:text-white",
      secondary:
        "border border-grey-lighter bg-white font-normal text-black hover:border-aop-basic-blue-lightest hover:bg-aop-basic-blue-lightest focus-visible:ring-blue-dark disabled:border-grey-lightest disabled:bg-white disabled:text-grey-lighter",
      tertiary:
        "border border-transparent bg-transparent text-aop-basic-blue hover:border-grey-lightest hover:bg-aop-basic-blue-lightest focus-visible:ring-blue-dark disabled:border-transparent disabled:bg-transparent disabled:text-grey-lighter",
      danger:
        "border border-red bg-white text-red hover:bg-red-lightest focus-visible:ring-red-dark disabled:border-red-lighter disabled:bg-white disabled:text-red-lighter",
      ghostPrimary:
        "text-aop-basic-blue hover:text-aop-basic-blue-dark focus:text-aop-basic-blue-dark focus-visible:ring-blue-dark disabled:text-grey-lighter",
      ghostSecondary:
        "text-black focus-visible:ring-blue-dark disabled:border-grey-lightest disabled:bg-white disabled:text-grey-lighter hocus:text-aop-basic-blue-dark",
      ai: "border border-aop-bright-purple bg-aop-bright-purple-lighter text-aop-bright-purple hover:bg-aop-bright-purple-light focus-visible:ring-aop-bright-purple disabled:border-grey-lighter disabled:bg-grey-lightest disabled:text-grey-lighter",
    },
    size: {
      sm: "h-8 px-2 text-sm",
      md: "h-10 px-4 text-base",
    },
  },
  compoundVariants: [
    {
      styling: ["ghostPrimary", "ghostSecondary"],
      className: "h-auto rounded-none px-0",
    },
  ],
  defaultVariants: {
    styling: "primary",
    size: "md",
  },
});

export type ButtonVariants = VariantProps<typeof buttonVariants>;
