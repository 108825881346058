import { ImageCropper } from "components/ImageCropper/ImageCropper";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import type { ImageMediaProps } from "components/Media/Media";
import { AvatarMedia, ImageMedia } from "components/Media/Media";
import { checkFileSize } from "helpers/file-size";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import { useCombinedRefs } from "hooks/useCombinedRef";
import { range } from "lodash-es";
import type { ChangeEvent, ForwardedRef } from "react";
import type React from "react";
import { forwardRef, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export interface ImageInputProps
  extends Pick<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "id" | "aria-invalid"
  > {
  ref?: ForwardedRef<HTMLInputElement>;
  value: FormImage[];
  onChange?: (files: FormImage[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  nOfImages?: number;
  theme?: "light" | "dark";
  isAvatarInput?: boolean;
  cropper?: boolean;
  texts?: ImageMediaProps["texts"];
  disabled?: boolean;
}

export const ImageInput = forwardRef<HTMLInputElement, ImageInputProps>(function ImageInput(
  { value: images, onChange, nOfImages = 1, theme = "light", cropper = false, texts, isAvatarInput, ...props },
  ref,
) {
  const { t } = useTranslation();

  const onImageChange: React.Dispatch<React.SetStateAction<FormImage[]>> = useMemo(() => {
    return (files: FormImage[] | ((oldFiles: FormImage[]) => FormImage[])) => {
      onChange?.(Array.isArray(files) ? files : files(images));
    };
  }, [images, onChange]);

  const [isCropperModalOpen, cropperModalHandler] = useBool(false);
  const [cropperImgFile, setCropperImgFile] = useState<File | null>(null);
  const [savedCropperImgFiles, setSavedCropperImgFiles] = useState<File[]>([]);

  const replacementIndex = useRef<number | undefined>(undefined);
  const innerRef = useRef<HTMLInputElement>(null);
  const combinedRef = useCombinedRefs(innerRef, ref);
  const { addImage, addImages, removeImage, replaceImage } = useImageInput({
    maximumFiles: nOfImages,
    onChange: onImageChange,
  });
  const [dragging, setDragging] = useState(false);
  const openFileDialog = () => combinedRef.current?.click();
  const deleteFile = (idx: number) => {
    if (cropperImgFile) {
      setCropperImgFile(null);
    }

    if (combinedRef.current) {
      removeImage(images[idx]);
      combinedRef.current.value = "";
      props.onBlur?.();
    }
  };
  const handleOnEdit = (idx: number) => {
    replacementIndex.current = idx;

    if (cropper) {
      if (!cropperImgFile || !savedCropperImgFiles.includes(cropperImgFile)) {
        openFileDialog();

        return;
      }

      cropperModalHandler.setTrue();

      return;
    }

    openFileDialog();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) {
      return;
    }

    if (isDefined(replacementIndex.current)) {
      replaceImage(images[replacementIndex.current], event.target.files[0]);
      replacementIndex.current = undefined;
    } else {
      addImages(event.target.files);
    }
  };

  const handleCropSave = (croppedImage: File) => {
    setSavedCropperImgFiles((prev) => [...prev, cropperImgFile!]);
    if (isDefined(replacementIndex.current)) {
      replaceImage(images[replacementIndex.current], croppedImage);
      replacementIndex.current = undefined;
    } else {
      addImage(croppedImage);
    }
  };

  const handleCropFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) {
      return;
    }

    setCropperImgFile(event.target.files[0]);
    cropperModalHandler.setTrue();

    if (combinedRef && combinedRef.current) {
      combinedRef.current.value = "";
    }

    return;
  };

  const handleCropClose = () => {
    cropperModalHandler.setFalse();
  };

  return (
    <>
      <div
        className="flex flex-wrap gap-2"
        onDrop={(event) => {
          if (event.dataTransfer.files && combinedRef.current) {
            combinedRef.current.files = event.dataTransfer.files;
            addImages(event.dataTransfer.files);
            setDragging(false);
            props.onBlur?.();

            event.preventDefault();
          }
        }}
        onDragOver={(event) => {
          event.preventDefault();
          event.dataTransfer.dropEffect = "copy";
        }}
        onDragEnter={() => setDragging(true)}
        onDragLeave={() => setDragging(false)}
      >
        {[...range(nOfImages)].map((idx) => {
          const disabled = images?.length < idx;

          if (disabled) return;

          if (isAvatarInput) {
            return (
              <AvatarMedia
                data-testid={`image_media_${idx}`}
                key={`ticket_img_frag_${idx}`}
                src={images?.[idx]?.url}
                aria-invalid={props["aria-invalid"] || (images?.[idx] && checkFileSize(images[idx]))}
                onAdd={() => openFileDialog()}
                onDelete={() => deleteFile(idx)}
                onEdit={() => handleOnEdit(idx)}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                {...props}
                className={dragging ? "border-aop-basic-blue" : undefined}
                disabled={props.disabled}
              />
            );
          } else {
            return (
              <ImageMedia
                data-testid={`image_media_${idx}`}
                key={`ticket_img_frag_${idx}`}
                src={images?.[idx]?.url}
                aria-invalid={props["aria-invalid"] || (images?.[idx] && checkFileSize(images[idx]))}
                onAdd={() => openFileDialog()}
                onDelete={() => deleteFile(idx)}
                onEdit={() => handleOnEdit(idx)}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                theme={theme}
                texts={texts}
                {...props}
                className={dragging ? "border-aop-basic-blue" : undefined}
                disabled={props.disabled}
              />
            );
          }
        })}

        <input
          data-testid="image_input"
          tabIndex={-1}
          type="file"
          {...props}
          accept="image/png, image/jpeg"
          className="sr-only"
          ref={combinedRef}
          onChange={cropper ? handleCropFileChange : handleFileChange}
          disabled={props.disabled}
          multiple={nOfImages > 1}
        />
      </div>
      {cropper ? (
        <ImageCropper
          title={t("model.service.profile-image.modal.title")}
          isOpen={isCropperModalOpen}
          onClose={handleCropClose}
          onSave={handleCropSave}
          imgFile={cropperImgFile}
        />
      ) : null}
    </>
  );
});
