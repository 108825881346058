import type { UseComboboxPropGetters } from "downshift";
import { useCombobox } from "downshift";
import { autoCompleteDisabledProperties } from "helpers/auto-complete-helpers";
import { twResolve } from "helpers/tw-resolve";
import { uniqBy } from "lodash-es";
import { memo, useMemo, useRef } from "react";
import { twJoin } from "tailwind-merge";

export interface InputAutocompleteProps
  extends Pick<React.InputHTMLAttributes<HTMLInputElement>, "id" | "placeholder" | "disabled" | "readOnly"> {
  value: string;
  suggestions: Suggestion[];
  "aria-invalid"?: boolean;
  onBlur?: () => void;
  onChange?: (value: string) => void;
}

export function InputAutocomplete({
  id,
  value,
  disabled,
  placeholder,
  suggestions,
  onChange,
  readOnly,
  "aria-invalid": isInvalid,
}: InputAutocompleteProps): React.ReactNode {
  const inputValueRef = useRef<HTMLInputElement>(null);
  const filteredItems = useMemo(() => {
    if (!value) {
      if (suggestions.length > 8) {
        return suggestions;
      }
    }

    const lowerCaseValue = value.toLowerCase();

    return suggestions.filter((item) => item.searchableValue.includes(lowerCaseValue));
  }, [value, suggestions]);
  const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps, getLabelProps, closeMenu } = useCombobox(
    {
      inputValue: value,
      defaultHighlightedIndex: 0,
      items: filteredItems,
      onSelectedItemChange(changes) {
        onChange?.(changes.selectedItem?.displayValue || "");
        closeMenu();
      },
      onStateChange: ({ type, selectedItem }) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
            if (selectedItem) {
              onChange?.(selectedItem?.displayValue ?? "");
            } else {
              inputValueRef.current?.form?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
            }
            closeMenu();
            break;
          case useCombobox.stateChangeTypes.ItemClick:
            onChange?.(selectedItem?.displayValue ?? "");
            closeMenu();
            break;
          case useCombobox.stateChangeTypes.InputBlur:
            closeMenu();
            break;
        }
      },
    },
  );

  return (
    <div className={twJoin("relative", isOpen && "z-10")} data-testid="input-autocomplete">
      <div>
        <div
          {...getLabelProps()}
          className={twResolve(
            "relative flex min-h-10 w-full cursor-default flex-wrap items-center rounded-lg border bg-white text-left leading-[26px] focus-within:border-grey-darker hover:border-aop-dark-blue focus-visible:border-aop-dark-blue",
            isOpen ? "rounded-b-none border-grey-darker hocus:border-grey-darker" : undefined,
            isInvalid ? "border-red-dark" : "border-grey-lighter",
            (disabled || readOnly) && "border-grey-lighter bg-grey-lightest hover:border-grey-lighter",
          )}
        >
          <input
            {...getInputProps({
              onChange: (e) => onChange?.(e.currentTarget.value),
              ref: inputValueRef,
            })}
            className={twResolve(
              "h-10 w-0 min-w-[30px] flex-1 rounded-lg px-2 py-1 text-base text-black focus:outline-none",
              (disabled || readOnly) && "text-grey-darker",
            )}
            id={id}
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={disabled || readOnly}
            {...autoCompleteDisabledProperties}
          />
        </div>
      </div>
      <ul
        {...getMenuProps()}
        className={twJoin(
          "absolute flex max-h-96 min-h-[2.25rem] w-full flex-col items-start overflow-auto rounded-lg rounded-t-none border border-t-0 border-grey-darker bg-white shadow-md hocus:outline-none",
          isOpen ? "opacity-100" : "opacity-0",
          filteredItems.length === 0 ? "hidden" : undefined,
        )}
      >
        {isOpen &&
          filteredItems.map((item, index) => (
            <ListItem
              key={item.displayValue}
              item={item}
              index={index}
              isHighlighted={highlightedIndex === index}
              getItemProps={getItemProps}
            />
          ))}
      </ul>
    </div>
  );
}

const ListItem = memo(ListItemInner) as typeof ListItemInner;

function ListItemInner({
  item,
  index,
  isHighlighted,
  getItemProps,
}: {
  item: Suggestion;
  index: number;
  isHighlighted: boolean;
  getItemProps: UseComboboxPropGetters<Suggestion>["getItemProps"];
}) {
  return (
    <li
      data-testid="input-autocomplete-item"
      {...getItemProps({ index, item })}
      className={twJoin(
        "w-full cursor-pointer select-none px-3 py-1 text-black first:mt-2 last:mb-2",
        isHighlighted ? "bg-blue-lightest" : undefined,
      )}
    >
      <div className="flex items-center">{item.displayValue}</div>
    </li>
  );
}

type Suggestion = { searchableValue: string; displayValue: string };

export function prepareSuggestions(items: string[]): Suggestion[] {
  return uniqBy(
    items.map((x) => ({ searchableValue: x.trim().toLowerCase(), displayValue: x })).filter((x) => x.searchableValue),
    (x) => x.displayValue,
  );
}
