import type {
  AssetBookingDetailDto,
  BookableAssetDeletedResult,
  BookableAssetDetailDto,
  BookableSlotDto,
  CreatedEntityDto,
} from "api/types";
import iconFile06 from "assets/icons/file-06.svg";
import iconUsersCheck from "assets/icons/users-check.svg";
import SuccessIcon from "assets/images/party.png";
import { AudiencePreview } from "components/AudienceSelector/AudiencePreview";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { type ContextMenuAction } from "components/ContextMenu/ContextMenu";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { EntityDetailsCard } from "components/EntityDetailsCard/EntityDetailsCard";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { Modal } from "components/Modal/Modal";
import { PageGrid } from "components/PageGrid/PageGrid";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Headline4 } from "components/Text/Text";
import { dayOfWeekIndex } from "helpers/date";
import { createRequiredStringRule } from "helpers/rules";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { BookingTimeslotSelector } from "modules/bookings/components/BookingTimeslotSelector";
import { daysOptions } from "modules/bookings/constants";
import { canManageAnyBookableAsset, canViewSchedule } from "modules/bookings/permissions";
import { useCallback, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export interface LayoutProps {
  assetDetails: BookableAssetDetailDto;
  assetSlots: BookableSlotDto[];
  bookingDetails: AssetBookingDetailDto | undefined;
  minDate: Date;
  maxDate: Date;
  defaultValues: DefaultCreateOrEditBookingFormV;
  onDateChange: (date: Date | null) => void;
  onSubmit: (data: CreateOrEditBookingFormValues) => Promise<CreatedEntityDto>;
  futureBookings: number;
  isSubmitting: boolean;
  onDelete: (id: string) => Promise<BookableAssetDeletedResult>;
}

export interface CreateOrEditBookingFormValues {
  date: Date;
  bookAllDay: boolean;
  startTime: number | null;
  endTime: number | null;
  reason?: string;
  isRegulationAccepted: boolean;
}

export type DefaultCreateOrEditBookingFormV = Omit<CreateOrEditBookingFormValues, "bookAllDay">;

export function Layout({
  assetDetails,
  assetSlots,
  bookingDetails,
  minDate,
  maxDate,
  defaultValues,
  onDateChange,
  onSubmit,
  futureBookings,
  isSubmitting,
  onDelete,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t, i18n } = useTranslation();
  const hasPermission = usePermission();
  const navigate = useNavigate();
  const sessionUser = useSessionUser();

  const [isSuccessModalOpen, successModalHandler] = useBool(false);
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("delete-asset-modal");
  const form = useForm<CreateOrEditBookingFormValues>({ defaultValues });

  const startTime = useWatch({ control: form.control, name: "startTime" });
  const endTime = useWatch({ control: form.control, name: "endTime" });
  const bookAllDay = useWatch({ control: form.control, name: "bookAllDay" });

  useEffect(() => {
    form.register("startTime", {
      validate: {
        required: () => {
          if (!bookAllDay && !startTime) return t("page.bookings.book-asset.form.booking-timeslot.error");
          if (startTime && !endTime) return t("page.bookings.book-asset.form.booking-timeslot.error");

          return undefined;
        },
      },
    });
  }, [form, t, startTime, bookAllDay, endTime]);

  const isDayDisabled = useCallback(
    (current: Date) => {
      const disabledDays = assetDetails.bookableDays
        .filter((bookableDay) => !bookableDay.enabled)
        .map((bookableDay) => daysOptions.indexOf(bookableDay.day));

      return disabledDays.includes(dayOfWeekIndex(current));
    },
    [assetDetails.bookableDays],
  );

  const handleDateChange = (date: DatePickerValue) => {
    onDateChange(date === "" ? null : date);
    if (date !== "") {
      form.setValue("date", date);
      form.setValue("startTime", null);
      form.setValue("endTime", null);
      form.setValue("bookAllDay", false);
    }
  };

  const handleSubmit = async () => {
    await onSubmit(form.getValues());

    successModalHandler.setTrue();
  };

  const actions: ContextMenuAction[] = [];
  if (assetDetails.canEdit) {
    actions.push({
      text: t("common.action.edit"),
      callback: () => navigate(routes.bookings.editAsset({ slug, id: assetDetails.id })),
    });
  }
  if (assetDetails.canDelete) {
    actions.push({
      text: t("common.action.delete"),
      callback: () => openDeleteModal(assetDetails.id),
    });
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={assetDetails.name}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.bookings.list-assets.title"),
              to: routes.bookings.list({ slug }),
            },
            {
              name: assetDetails.name,
            },
          ]}
        />
      }
    >
      <PageGrid.Grid>
        <PageGrid.Item size="50%">
          <EntityDetailsCard
            title={assetDetails.name}
            description={assetDetails.description}
            images={assetDetails.images}
            renderLabels={() => (
              <>
                {assetDetails.hasAudience && sessionUser.isAdmin && (
                  <Label theme="blue">
                    <Icon size={16} name={iconUsersCheck} />
                  </Label>
                )}
              </>
            )}
            renderBody={() => (
              <>
                {assetDetails.audience &&
                  assetDetails.audience.length > 0 &&
                  sessionUser.isAdmin &&
                  hasPermission(canManageAnyBookableAsset) && (
                    <AudiencePreview audience={assetDetails.audience} readOnly />
                  )}
              </>
            )}
            {...{ actions }}
          />
        </PageGrid.Item>
        <PageGrid.Item size="50%">
          <div className="flex h-fit flex-col gap-4 rounded-lg bg-white p-4 shadow-sm">
            <Headline4>{t("page.bookings.book-asset.section.book-asset.title")}</Headline4>
            {maxDate < minDate && <p>{t("page.bookings.book-asset.section.book-asset.not-available")}</p>}
            {maxDate >= minDate && (
              <Form formMethods={form} onSubmit={handleSubmit}>
                <FormContent maxWidth="4xl">
                  <FormField label={t("page.bookings.book-asset.form.booking-date.label")}>
                    <FormDateAndTimePicker<CreateOrEditBookingFormValues, "date">
                      data-testid="date-picker-input"
                      name="date"
                      min={minDate}
                      max={maxDate}
                      disabledDate={isDayDisabled}
                      onChange={handleDateChange}
                      rules={{
                        validate: {
                          laterThanMin: (date) => {
                            if (!date) {
                              return undefined;
                            }

                            return date < minDate
                              ? t("page.bookings.book-asset.form.booking-date.error.must-be-after-date", {
                                  date: formatDate(i18n, "date", minDate),
                                })
                              : undefined;
                          },
                          soonerThanMax: (date) => {
                            if (!date) {
                              return undefined;
                            }

                            if (!maxDate) {
                              return;
                            }

                            return date > maxDate
                              ? t("page.bookings.book-asset.form.booking-date.error.must-be-before-date", {
                                  date: formatDate(i18n, "date", maxDate),
                                })
                              : undefined;
                          },
                        },
                      }}
                    />
                  </FormField>
                  <FormErrorWrapper name="startTime" encircle>
                    <BookingTimeslotSelector
                      timeslot={assetDetails.timeslot}
                      timeslots={assetSlots}
                      booking={bookingDetails}
                      isBookMultipleTimeslotsAllowed={assetDetails.canBookMultipleSlots}
                    />
                  </FormErrorWrapper>
                  {assetDetails.requireBookingReason && (
                    <FormField label={t("page.bookings.book-asset.form.booking-reason.label")} required>
                      <FormInput<CreateOrEditBookingFormValues, "reason">
                        name="reason"
                        placeholder={t("page.bookings.book-asset.form.booking-reason.placeholder")}
                        rules={{
                          validate: {
                            required: createRequiredStringRule(t, "page.bookings.book-asset.form.booking-reason.name"),
                          },
                        }}
                      />
                    </FormField>
                  )}
                  {assetDetails.regulationDocument && (
                    <FormErrorWrapper name="isRegulationAccepted" encircle>
                      <div className="flex flex-col gap-1">
                        <FormCheckbox
                          name="isRegulationAccepted"
                          label={t("page.bookings.book-asset.form.booking-regulations.label")}
                          alignTop
                          rules={{
                            validate: {
                              required: createRequiredStringRule(
                                t,
                                "page.bookings.book-asset.form.booking-reason.name",
                              ),
                            },
                          }}
                        />
                        <Button
                          styling="tertiary"
                          onClick={() => window.open(assetDetails.regulationDocument?.url, "_blank")}
                          icon={<Icon name={iconFile06} size={20} />}
                          className="max-w-full"
                        >
                          <span className="truncate">{assetDetails.regulationDocument?.fileName}</span>
                        </Button>
                      </div>
                    </FormErrorWrapper>
                  )}
                  <Button className="self-end" type="submit" isLoading={isSubmitting}>
                    {t("page.bookings.book-asset.book")}
                  </Button>
                </FormContent>
              </Form>
            )}
          </div>
        </PageGrid.Item>
      </PageGrid.Grid>

      <Modal isOpen={isSuccessModalOpen} isActionRequired shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false}>
        <div className="flex flex-col items-center justify-between gap-8 p-4">
          <Headline4>{t("page.bookings.book-asset.success-modal.title")}</Headline4>
          <img src={SuccessIcon} alt="success" />
          <div className="flex w-full flex-col items-center gap-2">
            <span className="w-full">
              <Button
                onClick={() =>
                  hasPermission(canViewSchedule)
                    ? navigate(routes.reservations.list({ slug }))
                    : navigate({ pathname: routes.calendar.list({ slug }), search: "tab=reservations" })
                }
                className="w-full"
              >
                {t("page.bookings.book-asset.success-modal.see-details")}
              </Button>
            </span>
            <Button styling="tertiary" onClick={() => navigate(routes.bookings.list({ slug }))}>
              {t("common.action.close")}
            </Button>
          </div>
        </div>
      </Modal>
      <DeleteModal
        title={t("page.bookings.delete-asset.modal.title")}
        description={
          futureBookings === 0
            ? t("page.bookings.delete-asset.modal.description.no-bookings")
            : t("page.bookings.delete-asset.modal.description", { count: futureBookings })
        }
        onDelete={onDelete}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
}
