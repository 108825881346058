import { Modal } from "components/Modal/Modal";
import type { ResidentPostType } from "modules/messages/constants";
import type React from "react";

import { ResidentCreatePost } from "./ResidentCreatePost";

interface ResidentCreatePostModalProps {
  isOpened: boolean;
  onClose: () => void;
  type: ResidentPostType | null;
}

export function ResidentCreatePostModal({ isOpened, onClose, type }: ResidentCreatePostModalProps): React.ReactNode {
  return (
    <Modal isOpen={isOpened} onRequestClose={onClose} shouldCloseOnEsc shouldCloseOnOverlayClick={false}>
      {type && <ResidentCreatePost messageType={type} {...{ onClose }} />}
    </Modal>
  );
}
