import { useImageResolver } from "api/hooks/useImageResolver";
import type { ProjectOverviewItemDto } from "api/types";
import { ProjectLogo } from "components/ProjectLogo/ProjectLogo";
import { Capture1, Capture2, Subtitle2 } from "components/Text/Text";
import { orderBy } from "lodash-es";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { calculateStats } from "../util";
import { AddProjectButton } from "./AddProjectButton";
import { BluePill } from "./BluePill";
import { Star } from "./Star";

interface Props {
  items: ProjectOverviewItemDto[];
  showEngagement: boolean;
  onShowAddProject: () => void;
  onChangeProject: (projectId: string) => void;
}

export function TilesView({ items, showEngagement, onShowAddProject, onChangeProject }: Props): React.ReactNode {
  const { t } = useTranslation();

  const sortedItems = useMemo(() => orderBy(items, (x) => x.name.toLocaleLowerCase()), [items]);

  const canSeeAdoptionOrEngagement = items.some(
    (x) => x.userRoleType !== "caretaker" && x.userRoleType !== "maintenance",
  );

  const resolveImage = useImageResolver();

  return (
    <div className="grid auto-rows-min grid-cols-1 gap-4 pb-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {sortedItems.map((x) => {
        const stats = calculateStats(x);

        return (
          <Tile key={x.id} onClick={() => onChangeProject(x.id)}>
            <div
              className="flex h-36 w-full rounded-t-[6px] bg-grey-lighter bg-cover bg-center bg-no-repeat px-4 group-hocus:brightness-95"
              style={{
                backgroundImage: x.backgroundImage ? `url(${resolveImage(x.backgroundImage, "intrinsic")})` : undefined,
              }}
            >
              <ProjectLogo className="ml-auto size-16 rounded-t-none" src={x.logoUrl} />
            </div>
            <div className="flex w-full flex-col px-4 py-5">
              <p className="flex items-center">
                <Subtitle2
                  className="truncate"
                  title={x.name + (x.type === "addressBased" ? "" : ` - ${t("page.portfolio.project-type.company")}`)}
                >
                  {x.name}
                </Subtitle2>
                {x.type === "companyBased" ? (
                  <span className="-my-1 ml-2 rounded bg-blue-lightest p-1 text-sm font-semibold text-blue-darker">
                    {t("page.portfolio.project-type.company")}
                  </span>
                ) : null}
              </p>
              <Capture1 className="truncate">{x.city}</Capture1>
              <div className="mt-2 grid grid-cols-[min-content_1fr] gap-x-2 gap-y-1 text-grey-dark">
                <Capture2 className="whitespace-nowrap">{t("page.portfolio.table.header.fah-rating")}</Capture2>
                <Capture1 className="flex items-center">
                  {stats.feelingAtHomeRating.value ?? "-"}/10{" "}
                  {!stats.feelingAtHomeRating.changePercentage ? (
                    <span className="ml-2">
                      <Star />
                    </span>
                  ) : (
                    <BluePill percentage={stats.feelingAtHomeRating.changePercentage} withStar />
                  )}
                </Capture1>
                <Capture2 className="whitespace-nowrap">{t("page.portfolio.table.header.avg-rating")}</Capture2>
                <Capture1 className="flex items-center">
                  {stats.averageTicketRating.value ?? "-"}/10{" "}
                  {!stats.averageTicketRating.changePercentage ? (
                    <span className="ml-2">
                      <Star />
                    </span>
                  ) : (
                    <BluePill percentage={stats.averageTicketRating.changePercentage} withStar />
                  )}
                </Capture1>
                {canSeeAdoptionOrEngagement ? (
                  <>
                    <Capture2 className="whitespace-nowrap">{t("page.portfolio.table.header.adoption")}</Capture2>
                    <Capture1 className="flex items-center">
                      {stats.adoptionPercentage.value ? stats.adoptionPercentage.value + "%" : "-"}
                      <BluePill percentage={stats.adoptionPercentage.changePercentage} />
                    </Capture1>
                  </>
                ) : null}
                {canSeeAdoptionOrEngagement && showEngagement ? (
                  <>
                    <Capture2 className="whitespace-nowrap">{t("page.portfolio.table.header.engagement")}</Capture2>
                    <Capture1 className="flex items-center">
                      {stats.engagedResidentPercentage.value ? stats.engagedResidentPercentage.value + "%" : "-"}
                      <BluePill percentage={stats.engagedResidentPercentage.changePercentage} />
                    </Capture1>
                  </>
                ) : null}
              </div>
            </div>
          </Tile>
        );
      })}
      <Tile>
        <div className="flex h-36 w-full items-center justify-center rounded-t-lg bg-blue-lighter bg-cover px-4">
          <svg
            className="h-16 text-blue-light"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          >
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </div>
        <div className="flex min-h-40 flex-1 flex-col items-center justify-center p-5">
          <AddProjectButton className="mx-auto" onClick={onShowAddProject}>
            {t("page.portfolio.add-new-project")}
          </AddProjectButton>
        </div>
      </Tile>
    </div>
  );
}

function Tile({ onClick, children }: React.PropsWithChildren<{ onClick?: () => void }>) {
  const Component = onClick ? "button" : "div";

  return (
    <Component
      type={onClick ? "button" : undefined}
      onClick={onClick}
      className={twJoin(
        "group flex w-full flex-col overflow-hidden rounded-lg border border-grey-lighter bg-white p-px text-left shadow",
        onClick ? "hocus:bg-grey-lightest hocus:shadow-md" : undefined,
      )}
    >
      {children}
    </Component>
  );
}
