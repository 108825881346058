import { DatePicker as AntDatePicker } from "antd";
import type { PickerProps } from "antd/es/date-picker/generatePicker";
import { getDateFromMinutesOfDay } from "modules/bookings/helpers";
import type { PickerRef } from "rc-picker";
import dateFnsConfig from "rc-picker/lib/generate/dateFns";
import type { DisabledTimes } from "rc-picker/lib/interface";
import React from "react";

const DatePicker = AntDatePicker.generatePicker<Date>(dateFnsConfig);

export type DatePickerValue = Date | "";

export interface DateAndTimePickerProps extends React.RefAttributes<PickerRef> {
  id?: string;
  name?: string;
  placeholder?: string;
  value: DatePickerValue;
  defaultValue?: Date;
  onChange: (value: DatePickerValue) => void;
  min?: Date;
  max?: Date;
  disabledDate?: (current: Date) => boolean;
  type?: "date" | "datetime" | "time";
  minuteStep?: PickerProps<Date>["minuteStep"];
  enableMidnight?: boolean;
  showNow?: boolean;
  allowClear?: boolean;
  error?: boolean;
  "data-testid"?: string;
}

export const DateAndTimePicker = React.forwardRef<PickerRef, DateAndTimePickerProps>(function DateAndTimePicker(
  {
    value,
    defaultValue,
    onChange,
    min,
    max,
    disabledDate,
    type = "date",
    minuteStep,
    enableMidnight = false,
    showNow = true,
    allowClear = false,
    error,
    ...props
  },
  ref,
): React.ReactNode {
  return (
    <DatePicker
      {...props}
      ref={ref}
      picker={type === "time" ? "time" : "date"}
      onChange={onChange}
      onCalendarChange={(val) => (val instanceof Date ? onChange(val) : onChange(""))}
      format={{
        format: type === "datetime" ? "DD/MM/YYYY HH:mm" : type === "date" ? "DD/MM/YYYY" : "HH:mm",
        type: "mask",
      }}
      showTime={
        type === "datetime" || type === "time" ? { format: "HH:mm", defaultOpenValue: defaultValue || min } : undefined
      }
      disabledTime={(current: Date) => disabledTime(enableMidnight, current, min, max)}
      minuteStep={minuteStep}
      value={typeof value === "number" ? getDateFromMinutesOfDay(value) : value}
      defaultValue={defaultValue}
      minDate={min}
      maxDate={max}
      disabledDate={disabledDate}
      showNow={showNow}
      allowClear={allowClear}
      needConfirm={false}
      status={error ? "error" : undefined}
      size="large"
      className="w-full"
    />
  );
});

function disabledTime(enableMidnight: boolean, current: Date, min?: Date, max?: Date): DisabledTimes {
  const disabledTimes: DisabledTimes = {};

  // Get disabled hours
  disabledTimes.disabledHours = () => {
    const hours = [];
    if (min && current.getDate() === min.getDate()) {
      for (let i = 0; i < min.getHours(); i++) {
        if (!enableMidnight || i !== 0) {
          hours.push(i);
        }
      }
    }

    if (max && current.getDate() === max.getDate()) {
      for (let i = max.getHours() + 1; i < 24; i++) {
        hours.push(i);
      }
    }

    return hours;
  };

  // Get disabled minutes
  disabledTimes.disabledMinutes = () => {
    const minutes = [];
    if (min && current.getDate() === min.getDate() && current.getHours() === min.getHours()) {
      for (let i = 0; i < min.getMinutes(); i++) {
        minutes.push(i);
      }
    }

    if (max && current.getDate() === max.getDate() && current.getHours() === max.getHours()) {
      for (let i = max.getMinutes() + 1; i < 60; i++) {
        minutes.push(i);
      }
    }

    return minutes;
  };

  return disabledTimes;
}
