import { autoCompleteDisabledProperties } from "helpers/auto-complete-helpers";
import type React from "react";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

export interface AuthCodeProps {
  ariaLabel?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  length?: number;
  placeholder?: string;
  onChange: (res: string) => void;
}

export interface AuthCodeRef {
  focus: () => void;
  clear: () => void;
}

// Blatantly stolen from https://github.com/drac94/react-auth-code-input
// But simplified to fit our needs
export const AuthCodeInput = forwardRef<AuthCodeRef, AuthCodeProps>(function AuthCode(
  { ariaLabel, autoFocus = true, disabled, length = 6, placeholder, onChange },
  ref,
) {
  const inputsRef = useRef<Array<HTMLInputElement>>([]);

  const inputPattern = "[a-zA-Z0-9]{1}";

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputsRef.current) {
        inputsRef.current[0].focus();
      }
    },
    clear: () => {
      if (inputsRef.current) {
        for (let i = 0; i < inputsRef.current.length; i++) {
          inputsRef.current[i].value = "";
        }
        inputsRef.current[0].focus();
      }
      sendResult();
    },
  }));

  useEffect(() => {
    if (autoFocus) {
      inputsRef.current[0].focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendResult = () => {
    const res = inputsRef.current.map((input) => input.value).join("");
    onChange?.(res);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, nextElementSibling },
    } = e;
    if (value.length > 1) {
      e.target.value = value.charAt(0);
      if (nextElementSibling !== null) {
        (nextElementSibling as HTMLInputElement).focus();
      }
    } else {
      if (value.match(inputPattern)) {
        if (nextElementSibling !== null) {
          (nextElementSibling as HTMLInputElement).focus();
        }
      } else {
        e.target.value = "";
      }
    }
    sendResult();
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;
    if (key === "Backspace") {
      if (target.value === "") {
        if (target.previousElementSibling !== null) {
          const t = target.previousElementSibling as HTMLInputElement;
          t.value = "";
          t.focus();
          e.preventDefault();
        }
      } else {
        target.value = "";
      }
      sendResult();
    }
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = e.clipboardData.getData("Text");

    let currentInput = 0;

    for (let i = 0; i < pastedValue.length; i++) {
      const pastedCharacter = pastedValue.charAt(i);
      const currentValue = inputsRef.current[currentInput].value;
      if (pastedCharacter.match(inputPattern)) {
        if (!currentValue) {
          inputsRef.current[currentInput].value = pastedCharacter;
          if (inputsRef.current[currentInput].nextElementSibling !== null) {
            (inputsRef.current[currentInput].nextElementSibling as HTMLInputElement).focus();
            currentInput++;
          }
        }
      }
    }
    sendResult();

    e.preventDefault();
  };

  return (
    <div
      className="flex justify-between"
      style={{
        width: `${length * 40}px`, // w-8 + p-0.5
      }}
    >
      {Array(length)
        .fill(undefined)
        .map((_, i) => (
          <input
            className="h-10 w-8 rounded-lg border border-grey-lighter bg-white p-0 text-center text-2xl font-semibold outline-none focus:shadow-azure focus:ring-1 focus:ring-aop-dark-blue"
            key={i}
            type="text"
            ref={(el: HTMLInputElement) => (inputsRef.current[i] = el)}
            maxLength={1}
            {...(i === 0 ? { autoComplete: "one-time-code" } : autoCompleteDisabledProperties)}
            aria-label={ariaLabel ? `${ariaLabel}. Character ${i + 1}.` : `Character ${i + 1}.`}
            disabled={disabled}
            placeholder={placeholder}
            pattern={inputPattern}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onFocus={handleOnFocus}
            onPaste={handleOnPaste}
          />
        ))}
    </div>
  );
});
