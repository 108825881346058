import type { BookableAssetDto, BookableSlotDto } from "api/types";
import { add, addDays, differenceInMinutes, format, parse, startOfDay } from "date-fns";

import type { TimeSlot } from "./constants";

// Convert server time string ("HH:mm:ss") to minutes of the day
// e.g. "08:00:00" -> 480
export const getMinutesOfDayFromTime = (time: string, type: "start" | "end"): number => {
  const date = getDateFromTimeString(time);

  if (type === "start" && time === "00:00:00") {
    return 0;
  } else if (type === "end" && time === "00:00:00") {
    return 1440;
  }

  return differenceInMinutes(date, startOfDay(date));
};

// Convert `Date` to minutes of the day
// e.g. new Date() -> 480
export const getDateFromMinutesOfDay = (minutes: number): Date => {
  return add(startOfDay(new Date()), { minutes });
};

// Convert minutes of the day to server time string ("HH:mm:ss")
// e.g. new Date() -> 480
export const getTimeFromMinutesOfDay = (minutes: number): string => {
  const date = startOfDay(new Date());
  const time = format(add(date, { minutes }), "HH:mm:ss");

  return time;
};

export const isTimeEndOfDay = (time: string): boolean => {
  return time === "00:00:00";
};

// Used to convert server time string to Date
export const getDateFromTimeString = (time: string, date?: Date): Date => {
  let targetDate = date;
  let timeFormat = "HH:mm:ss";

  if (!targetDate) {
    targetDate = new Date();
  }

  if (time.split(":").length === 2) {
    timeFormat = "HH:mm";
  }

  return parse(time, timeFormat, targetDate);
};

export const getTimeslotRange = (timeslot: BookableSlotDto): { startTime: number; endTime: number } => {
  return {
    startTime: getMinutesOfDayFromTime(timeslot.startTime, "start"),
    endTime: getMinutesOfDayFromTime(timeslot.endTime, "end"),
  };
};

export function getAllowedViewableDateRange(asset: BookableAssetDto): { minViewDate: Date; maxViewDate: Date } {
  const minViewDate = getMinDate(asset);
  const maxViewDate = getMaxDate(asset);

  return { minViewDate, maxViewDate };
}

export const parseTimeslotAsAmountMinutes = (timeslot: TimeSlot): number => {
  switch (timeslot) {
    case "fifteenMinutes":
      return 15;
    case "thirtyMinutes":
      return 30;
    case "oneHour":
      return 60;
    case "twoHours":
      return 120;
    case "threeHours":
      return 180;
    case "fourHours":
      return 240;
    case "allDay":
      return 1440;
  }
};

function getMinDate(asset: BookableAssetDto) {
  let minDate = new Date(asset.createdAt);

  if (!asset.canEdit) {
    if (asset.publishAt) {
      const publishAt = new Date(asset.publishAt);
      minDate = minDate > publishAt ? minDate : publishAt;
    }

    if (asset.availableFrom) {
      const availableFrom = new Date(asset.availableFrom);
      minDate = minDate > availableFrom ? minDate : availableFrom;
    }
  }

  return minDate;
}

function getMaxDate(asset: BookableAssetDto) {
  let maxDate = addDays(new Date(), 365);

  if (asset.unpublishAt) {
    const unpublishAt = new Date(asset.unpublishAt);
    maxDate = maxDate > unpublishAt ? unpublishAt : maxDate;
  }

  if (asset.maxDaysInAdvance != null) {
    const toInAdvance = addDays(new Date(), asset.maxDaysInAdvance - 1);
    maxDate = maxDate > toInAdvance ? toInAdvance : maxDate;
  }

  return maxDate;
}

export function getBookingsExportFilename(projectName: string, assetName?: string): string {
  const cleanProjectName = projectName.replaceAll(/[^a-zA-Z0-9()| _-]+/g, "");
  const date = new Date()
    .toISOString()
    .substring(0, 16)
    .replaceAll(/[T\-:]/g, "");
  let fileName = `${date}_${cleanProjectName}.xlsx`;
  if (assetName) {
    const cleanAssetName = assetName.split(" ").join("_");
    fileName = `${cleanAssetName}_${fileName}`;
  }

  return fileName;
}
