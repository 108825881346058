import iconClockPlus from "assets/icons/clock-plus.svg";
import iconTrash01 from "assets/icons/trash-01.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { Icon } from "components/Icon/Icon";
import { isDefined } from "helpers/util";
import type { BookableDay } from "modules/bookings/constants";
import { defaultBookableDayEndTime, defaultBookableDayStartTime } from "modules/bookings/constants";
import {
  getDateFromMinutesOfDay,
  getMinutesOfDayFromTime,
  parseTimeslotAsAmountMinutes,
} from "modules/bookings/helpers";
import type React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { CreateOrEditAssetFormValues } from "../Layout";

interface AssetBookableDaySelectorProps {
  bookableDay: BookableDay;
  bookableDayIndex: number;
  onChangeStartTime: (index: number, value: DatePickerValue) => void;
  onChangeEndTime: (index: number, value: DatePickerValue) => void;
}

export function AssetBookableDaySelector({
  bookableDay,
  bookableDayIndex,
  onChangeStartTime,
  onChangeEndTime,
}: AssetBookableDaySelectorProps): React.ReactNode {
  const { t } = useTranslation();
  const formMethods = useFormContext<CreateOrEditAssetFormValues>();
  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: `bookableDays.${bookableDayIndex}.times`,
  });

  // `watch` required to trigger re-rendering
  const timeslot = formMethods.watch("timeslot");
  const watchedBookableTimes = bookableDay.times;
  const bookableTimes = fields.map((bookableTime, index) => {
    return {
      ...bookableTime,
      ...watchedBookableTimes[index],
    };
  });

  const onClickAddBookableTime = () => {
    let newStartTime: number | null = getMinutesOfDayFromTime(defaultBookableDayStartTime, "start");
    let newEndTime: number | null = getMinutesOfDayFromTime(defaultBookableDayEndTime, "end");

    if (bookableTimes.length === 0) {
      formMethods.setValue(`bookableDays.${bookableDayIndex}.enabled`, true);
    } else {
      const lastBookableTime = bookableTimes[bookableTimes.length - 1];
      newStartTime = lastBookableTime.endTime;
      newEndTime = null;
    }

    append({
      startTime: newStartTime,
      endTime: newEndTime,
    });
  };

  const onClickRemoveBookableTime = (bookableTimeIndex: number) => {
    if (bookableTimes.length === 1) {
      formMethods.setValue(`bookableDays.${bookableDayIndex}.enabled`, false);
    }

    remove(bookableTimeIndex);
  };

  const isSelectorDisabled = !timeslot;
  const lastBookableTime = bookableTimes.length > 0 && bookableTimes[bookableTimes.length - 1];
  const isAddNewBookableTimeAllowed =
    lastBookableTime && isDefined(lastBookableTime.endTime) && lastBookableTime.endTime < 1440 && timeslot !== "allDay";
  // A time period contains zombie time if the bookable time duration is not a multiple of the selected timeslot size.
  // e.g. Timeslot = 2hr; Time period = 08:00 - 10:30; Zombie time is present (30 min).
  const isZombieTimePresent =
    timeslot &&
    timeslot !== "allDay" &&
    bookableTimes.some((currBookableTime) => {
      return (
        isDefined(currBookableTime.startTime) &&
        isDefined(currBookableTime.endTime) &&
        (currBookableTime.endTime - currBookableTime.startTime) % parseTimeslotAsAmountMinutes(timeslot) !== 0
      );
    });

  return (
    <>
      <div
        data-testid="bookable-day-section"
        className="flex flex-wrap items-start gap-2 lg:grid lg:grid-cols-[1fr_0.8fr] lg:gap-10"
      >
        <div className="flex h-10 w-full flex-col justify-center">
          <p>{t(`common.date.weekday.${bookableDay.day}`)}</p>
          {!isSelectorDisabled && isZombieTimePresent && (
            <p className="text-sm text-grey">
              {t("page.bookings.create-or-edit.form.section.booking-details.bookable-days.zombie-time-present.label")}
            </p>
          )}
        </div>
        <div className="flex w-full items-start justify-center gap-10 justify-self-end">
          <div className="relative flex w-full flex-col gap-2">
            {bookableTimes.length === 0 && (
              <Button
                className="w-full"
                styling="secondary"
                onClick={onClickAddBookableTime}
                disabled={isSelectorDisabled}
                icon={<Icon name={iconClockPlus} />}
                data-testid="add-first-bookable-time-btn"
              >
                {t("page.bookings.create-or-edit.form.section.booking-details.bookable-days.add-opening-time.btn")}
              </Button>
            )}
            {bookableTimes.length > 0 &&
              bookableTimes.map((currBookableTime, bookableTimeIndex) => {
                const timePickerStep = 15; // 15 minutes
                const isTimePickerDisabled = !bookableDay.enabled || isSelectorDisabled;
                const islastBookableTime = bookableTimeIndex === bookableTimes.length - 1;
                const prevBookableTime = bookableTimes.length > 1 && bookableTimes[bookableTimeIndex - 1];

                return (
                  <div key={currBookableTime.id} className="flex items-center gap-2">
                    {/* Start time */}
                    <div className="w-full min-w-[92px]">
                      <FormDateAndTimePicker<
                        CreateOrEditAssetFormValues,
                        `bookableDays.${typeof bookableDayIndex}.times.${typeof bookableTimeIndex}.startTime`
                      >
                        name={`bookableDays.${bookableDayIndex}.times.${bookableTimeIndex}.startTime`}
                        type="time"
                        min={
                          prevBookableTime && prevBookableTime.endTime
                            ? getDateFromMinutesOfDay(prevBookableTime.endTime)
                            : undefined
                        }
                        minuteStep={timePickerStep}
                        onChange={(value) => onChangeStartTime(bookableTimeIndex, value)}
                        rules={{
                          validate: {
                            required: (startTime) => {
                              if (!isDefined(startTime) && bookableDay.enabled && !bookableDay.allDay) {
                                return "";
                              }
                            },
                            soonerThanEndTime: (startTime) => {
                              if (
                                isDefined(startTime) &&
                                isDefined(currBookableTime.endTime) &&
                                startTime >= currBookableTime.endTime
                              ) {
                                return "";
                              }
                            },
                            longerThanTimeslot: (startTime) => {
                              if (
                                isDefined(startTime) &&
                                isDefined(currBookableTime.endTime) &&
                                timeslot &&
                                currBookableTime.endTime - startTime < parseTimeslotAsAmountMinutes(timeslot)
                              ) {
                                return "";
                              }
                            },
                          },
                        }}
                        data-testid="start-time-picker-input"
                        disabled={isTimePickerDisabled}
                      />
                    </div>
                    <label className="text-nowrap">
                      {t("page.bookings.create-or-edit.form.section.booking-details.bookable-days.days.to.label")}
                    </label>
                    {/* End time */}
                    <div className="w-full min-w-[92px]">
                      <FormDateAndTimePicker<
                        CreateOrEditAssetFormValues,
                        `bookableDays.${typeof bookableDayIndex}.times.${typeof bookableTimeIndex}.endTime`
                      >
                        name={`bookableDays.${bookableDayIndex}.times.${bookableTimeIndex}.endTime`}
                        type="time"
                        minuteStep={timePickerStep}
                        onChange={(value) => onChangeEndTime(bookableTimeIndex, value)}
                        rules={{
                          validate: {
                            required: (endTime) => {
                              if (!isDefined(endTime) && bookableDay.enabled && !bookableDay.allDay) {
                                return "";
                              }
                            },
                            laterThanStartTime: (endTime: number | null) => {
                              if (
                                isDefined(currBookableTime.startTime) &&
                                isDefined(endTime) &&
                                currBookableTime.startTime >= endTime
                              ) {
                                return "";
                              }
                            },
                            longerThanTimeslot: (endTime) => {
                              if (
                                isDefined(endTime) &&
                                isDefined(currBookableTime.startTime) &&
                                timeslot &&
                                endTime - currBookableTime.startTime < parseTimeslotAsAmountMinutes(timeslot)
                              ) {
                                return "";
                              }
                            },
                          },
                        }}
                        data-testid="end-time-picker-input"
                        disabled={isTimePickerDisabled}
                      />
                    </div>
                    <IconButton
                      styling="secondary"
                      title={t(
                        "page.bookings.create-or-edit.form.section.booking-details.bookable-days.days.remove-opening-time.btn.tooltip",
                      )}
                      onClick={() => onClickRemoveBookableTime(bookableTimeIndex)}
                      withTooltip={false}
                      disabled={isSelectorDisabled}
                      data-testid="delete-bookable-time-btn"
                    >
                      <Icon name={iconTrash01} />
                    </IconButton>
                    <IconButton
                      className={isAddNewBookableTimeAllowed && islastBookableTime ? "visible" : "invisible"}
                      aria-hidden={!isAddNewBookableTimeAllowed || !islastBookableTime}
                      styling="secondary"
                      title={t(
                        "page.bookings.create-or-edit.form.section.booking-details.bookable-days.days.add-opening-time.btn.tooltip",
                      )}
                      onClick={onClickAddBookableTime}
                      withTooltip={false}
                      disabled={isSelectorDisabled}
                      data-testid="add-bookable-time-btn"
                    >
                      <Icon name={iconClockPlus} />
                    </IconButton>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
