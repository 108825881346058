import { autoCompleteDisabledProperties } from "helpers/auto-complete-helpers";
import { twResolve } from "helpers/tw-resolve";
import type { ForwardedRef, TextareaHTMLAttributes } from "react";
import type React from "react";
import { forwardRef } from "react";

export interface ScalingTextAreaProps
  extends Omit<React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, "ref"> {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  icon?: React.ReactNode;
  toolbar?: React.ReactNode;
}

export const ScalingTextArea = forwardRef(function AutoTextArea(
  { icon, toolbar, className, ...props }: TextareaHTMLAttributes<HTMLTextAreaElement> & ScalingTextAreaProps,
  forwardedRef: ForwardedRef<HTMLTextAreaElement>,
) {
  return (
    <div className="relative w-full">
      {toolbar}
      {icon != null && (
        <div className="absolute left-0 top-0 flex h-10 w-7 items-center justify-center text-grey-light">{icon}</div>
      )}
      <textarea
        {...props}
        style={{
          height: "inherit",
        }}
        className={twResolve("w-full resize-none appearance-none text-base", className)}
        ref={(ref) => {
          if (typeof forwardedRef === "function") {
            forwardedRef(ref);
          } else if (forwardedRef) {
            forwardedRef.current = ref;
          }

          if (ref) {
            ref.style.height = "inherit";
            ref.style.height = `${Math.min(ref.scrollHeight + 2, 260)}px`;
          }
        }}
        rows={1}
        {...(props.autoComplete === undefined || props.autoComplete === "off" ? autoCompleteDisabledProperties : {})}
      />
    </div>
  );
});
